@import "../../scss/utils";

.GetWallet {
  height: 100%;
}

.GetWallet-text {
  display: flex;
  text-align: center;
  flex-direction: column;
  font-size: 28px;
  line-height: 39px;
  font-weight: 300;

  @include sm {
    padding-bottom: 40px;
  }

  @include md {
    font-size: 48px;
    line-height: 66px;
    padding-bottom: 42px;
  }

  @include xxl {
    font-size: 64px;
    line-height: 89px;
    padding-bottom: 48px;
  }
}

.GetWallet-action {
  display: flex;
  justify-content: center;
}
