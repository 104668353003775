@import "../../scss/utils";

.Wallet-actions {
  align-self: flex-start;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  @include sm {
    width: 100%;
  }
}

.Banner-actions {
  align-self: flex-start;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr min-content;
  grid-gap: 20px;

  @include sm {
    grid-template-columns: 1fr;
    grid-gap: 16px;
    align-self: center;
    width: 100%;
  }
}

.Banner-links {
  align-self: flex-start;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr min-content min-content;
  grid-gap: 20px;

  @include sm {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 16px;
    align-self: center;
    width: 100%;
  }
}

.QqCodeTooltip {
  border-radius: 20px !important;
  opacity: 1 !important;
}
