@import "../../scss/utils";

.Footer {
}

.Footer-bottom a {
  color: $grey;
  font-size: 14px;
}

.Footer-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 60px;
  padding-bottom: 35px;

  @include sm {
    flex-direction: column;
  }
}

.Footer-nav {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  column-gap: 60px;
  grid-auto-flow: column;

  @include sm {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
}

.Footer-nav-item {
  padding: 6px 0;
  cursor: pointer;
  font-size: 18px;
  line-height: 23px;
  text-decoration: none;
  color: $grey;

  &:hover {
    color: $yellow-d;
  }
}

.Footer-nav-contact-us {
  padding: 6px 0;
  color: $grey;
  font-size: 14px;
  line-height: 18px;

  @include sm {
    padding-top: 60px;
  }
}

.Footer-subscribe {
  font-size: 18px;
  line-height: 23px;
  width: 40%;
  color: $grey;

  .Contact-form {
    min-width: auto;
  }

  @include sm {
    width: 100%;
    margin-top: 50px;

    input, .btn {
      margin-left: 0;
    }
  }
}

.Footer-subscribe-info {
  margin: 25px 10px 0;

  @include sm {
    margin-left: 0;
    margin-right: 0;
  }
}

.Footer-bottom {
  padding: 38px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $grey;
  font-size: 14px;

  .Logo-icon {
    display: none;
  }

  @include sm {
    .Logo {
      display: none;
    }

    .Logo-icon {
      display: block;
    }
  }
}

.Divider {
  border: none;
  height: 1px;
  color: #353842; /* old IE */
  background-color: #353842; /* Modern Browsers */
}
