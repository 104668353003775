@import "../../scss/utils";

.Pricing {
  display: flex;
  flex-direction: column;
  margin: 0 -12px;

  a {
    color: $yellow-d;
  }

  @include lg {
    flex-direction: row;
  }
}

.Pricing-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Pricing-item-card {
  background: $dark;
  border-radius: 24px;
  padding: 42px 32px;
}

.Pricing-title {
  font-weight: 600;
  font-size: 40px;
  text-transform: capitalize;
}

.Pricing-amount {
  font-weight: 400;
  font-size: 24px;
  color: $yellow_d;
  margin-top: 8px;
}

.Pricing-desc {
  font-weight: 400;
  font-size: 16px;
  color: $grey;
  margin-top: 24px;
}

.Pricing-save {
  font-size: 20px;
  color: $yellow_d;
  margin-top: 8px;
}

.Pricing-button {
  margin-top: 32px;
}

.Pricing-button-text {
  color: $dark !important;
  text-decoration: none !important;
}

.Premium-space-top {
  padding-top: 80px;
}

.Premium-space-bottom {
  padding-bottom: 80px;
}
