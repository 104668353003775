@import "../../scss/utils";

.Markdown {
  overflow: hidden;
  font-size: 18px;
  line-height: 28px;
  color: $light-steal;

  @include sm {
    font-size: 14px;
    line-height: 22px;
  }

  p, ul, ol, blockquote {
    margin-top: 12px;
    margin-bottom: 24px;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  a {
    color: $yellow-l;
    text-decoration: underline;
  }

  ul, ol {
    list-style: circle;
    padding-left: 1.5em;
  }

  .icon-link {
    width: 16px;
    height: 16px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADiSURBVHgBzZLNDYJAEIXfAhcPJpZAJ5ZACdbA1cSA8edsC5ZgB1qJlEBiiCd2nFkBCcye9uK7wM7uvJn5dg0Uvc/YtBYXA6zgE6GKYpRG22tOePKB2hJuvvzIIONPmnjcU07eL3cofQavgzMpIgQq2CDxAZP2miMKt+iALba4zgx4ViFZjYFJMsfuLeHRrTPLRfh3bqABk8qS3Md6YNoI4Qw88Vralsqi2GBN307KobK8A2ajGpBFbmJ3x65t+iUNY3Cs5v5zmZfGzppkX85pe3/wkDABplbpgKkGU2CaBmCKPsIJXILYmYoSAAAAAElFTkSuQmCC');
    background-size: cover;
    display: block;
    visibility: hidden;
  }

  h1, h2, h3 {
    position: relative;

    a {
      position: absolute;
      left: -30px;
      padding: 5px 10px 5px 5px;
    }

    &:hover .icon,
    a:hover .icon {
      visibility: visible;
    }
  }

  h1 {
    font-size: 32px;
    line-height: 44px;
    font-weight: 600;

    a {
      top: 10px;

      @include sm {
        left: -25px;
      }
    }
  }

  h2 {
    font-size: 24px;
    line-height: 33px;
    font-weight: 500;

    a {
      top: 5px;
      left: -25px;
    }

    .icon {
      width: 15px;
      height: 15px;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 27px;

    a {
      top: 3px;
      left: -23px;
    }

    .icon {
      width: 13px;
      height: 13px;
    }
  }

  pre {
    overflow: auto;
  }

  blockquote {
    color: $grey;
    padding: 12px 12px 12px 16px;
    position: relative;
    font-weight: 600;

    &:before {
      position: absolute;
      top: 13px;
      bottom: 13px;
      margin-left: -16px;
      width: 4px;
      display: block;
      content: "";
      background: $grey;
    }

    @include md {
      padding-left: 24px;

      &:before {
        margin-left: -24px;
      }
    }

    > * {
      margin: 0;
    }
  }
}

