@import "../../scss/utils";

.Featured {
  height: 100%;
}

.Featured-text {
  display: flex;
  text-align: center;
  flex-direction: column;
  font-size: 28px;
  line-height: 39px;
  font-weight: 400;
  padding-top: 80px;

  @include sm {
    padding-bottom: 40px;
  }

  @include md {
    font-size: 48px;
    line-height: 66px;
    padding-bottom: 60px;
  }

  @include xxl {
    font-size: 64px;
    line-height: 89px;
    padding-bottom: 90px;
  }
}

.Featured-list {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;

  @include md {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include lg {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.Featured-item {
  margin: 10px;

  @include sm {
    width: 80%;
  }

  @include md {
    margin: 15px;
    height: 40px;
  }

  @include xxl {
    height: 50px;
  }
}
