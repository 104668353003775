@import "../../scss/utils";

.FeaturesList {

}

.Features-list-card {
  font-family: $font-family-base;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 16px 0;
}

.Features-list-body {
  border-top: 1px solid $steel_20;
  padding-top: 12px;
}

.Features-list-title {
  font-weight: 400;
  font-size: 16px;
}

.Features-list-text {
  font-weight: 400;
  font-size: 12px;
}

.Features-premium-text {
  font-weight: 400;
  font-size: 18px;
}
