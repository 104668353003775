/*
 * Typography
 * ======================================================================== */

$font-family-base: 'Noto Sans', sans-serif;

/*
 * Colors
 * ======================================================================== */

// Colors

$ripple_white:        #ffffffb2;
$ripple_black:        #0000001f;
$transparent:         #00000000;
$yellow_d:            #ffa800;
$yellow_l:            #f58400;
$green_d:             #05C46B;
$green_l:             #04AD5F;
$red_d:               #ff4820;
$red_l:               #ed402e;
$issyk_blue:          #1a60ff;
$steel_dark:          #252933;
$light_grey:          #c8c7cc;
$steel_light:         #ebebf0;
$white_pressed:       #e3e4e8;
$darkest:             #0f1014;

$dark_80:             #13151ACC;
$black_50:            #00000080;
$white_20:            #FFFFFF33;
$white_50:            #ffffff80;
$green_50:            #13D67080;
$yellow_50:           #ffa80080;

$grey_50:             #80808580;
$steel_10:            #6E78991a;
$steel_20:            #6E789933;
$steel_dark_pressed:  #353842;
$red_50:              #ff482080;
$issykBlue:           #3372FF;

// Overrides
$light:       #f5f5f5;
$light-steal: #EBEBF0;
$light-soft:  #E1E1E5;
$dark:        #13151a;
$dark-light:  #37393B;
$dark-steal:  #252933;
$white:       #ffffff;
$black:       #000000;
$green:       $green_d;
$grey:        #808085;
$grey-light:  #C8C7CC;
$yellow:      $yellow_d;

// Border
$border-color:  $steel-20;
$list-group-border-color: $border-color;

// Input
$input-placeholder-color: $grey_50;

/*
 * Layout
 * ======================================================================== */

$max-content-width: 1000px;

/*
 * Media queries breakpoints
 * ======================================================================== */

$screen-sm: 576px  !default;
$screen-md: 768px  !default;
$screen-lg: 992px  !default;
$screen-xl: 1200px !default;
$screen-xxl: 1441px !default;

@mixin sm { @media (max-width: $screen-md) { @content; } }
@mixin md { @media (min-width: $screen-md) { @content; } }
@mixin lg { @media (min-width: $screen-lg) { @content; } }
@mixin xl { @media (min-width: $screen-xl) { @content; } }
@mixin xxl { @media (min-width: $screen-xxl) { @content; } }

@import "~bootstrap/scss/bootstrap";
