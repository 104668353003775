@import "../../scss/utils";

.Button {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
  padding: 10px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: $grey;

  @include sm {
    justify-content: center;
  }

  @include md {
    padding: 6px 20px;
  }

  @include lg {
    width: max-content;
    padding: 10px 24px;
  }

  svg, .Icon {
    margin-right: 8px;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: $grey_50;
    background: $steel_20;
    cursor: not-allowed;
  }
}

.Button-bordered {
  border: 1px solid $grey-light;
}

.Button-circle {
  border-radius: 40px;
}

.Button-nowrap {
  white-space: nowrap;
}

.Button-download {
  padding: 10px 0;

  @include sm {
    .Button-text {
      display: none;
    }
  }
}

.Button-circle-yellow {
  border: 1px solid $yellow-d;
  background: $yellow-d;
  color: $dark;

  path {
    fill: white;
  }
}

.Button-yellow {
  background: $yellow-d;
  color: $dark;

  &:enabled:hover {
    background: lighten($yellow-d, 2%);
  }
}

.Button-grey {
  background: $grey;
  color: $dark;

  &:enabled:hover {
    background: lighten($grey, 2%);
  }
}

.Button-steal20 {
  background: $steel_20;
  color: $white;

  &:enabled:hover {
    background: lighten($steel_20, 2%);
  }
}

.Button-steal {
  background: $dark-steal;
  color: $white;

  &:enabled:hover {
    background: lighten($dark-steal, 2%);
  }
}

.Button-dark {
  background: $dark;
  color: $white;

  &:enabled:hover {
    background: lighten($dark, 2%);
  }
}

.Button--loading {
  display: none;
  opacity: 0.6;
  cursor: not-allowed;
}
