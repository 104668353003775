@import "../../scss/utils";

.Container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  word-wrap: break-word;
  box-sizing: border-box;
  position: relative;

  @include sm {
    max-width: 540px;
    padding: 0 24px;
  }

  @include md {
    max-width: 720px;
  }

  @include lg {
    max-width: 960px;
  }

  @include xl {
    max-width: 1170px;
  }

  @include xxl {
    max-width: 1530px;
  }
}

.Container-fluid {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}

.Container-clipped {
  overflow: hidden;
}
