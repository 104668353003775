@import "../../scss/utils";

.Section-join {
  padding-bottom: 90px;

  @include sm {
    padding-top: 30px; // +60
  }

  @include md {
    padding-bottom: 70px;
  }

  @include lg {
    padding-bottom: 110px;
  }
}

.Join-section-text,
.Join-section-info {
  width: 100%;
  display: flex;
  text-align: left !important;
  justify-content: left !important;

  @include sm {
    justify-content: center !important;
    text-align: center !important;
  }
}

.Join-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @include xxl {
    width: 80%;
    align-self: center;
  }
}

.Join-icon {
  padding: 15px;

  @include sm {
    padding: 7px;
  }
}

.Join-image {
  width: 70px;
  transition: transform .4s ease-in-out;

  @include md {
    width: 100px;
  }

  @include lg {
    width: 120px;
  }

  @include xxl {
    width: 160px;
  }

  &:hover {
    transform: scale(1.1);
    //transform: rotate(360deg);
  }
}
