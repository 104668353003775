@import "../../scss/utils";

.Caution {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  text-align: center;
  color: $red
}

.Banner {
  display: flex;
  margin-top: 80px;
  position: relative;

  @include sm {
    margin-top: 20px;
    flex-direction: column;
  }
}

.Banner-start, .Banner-end {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @include sm {
    width: 100%;
  }

  @include md {
    height: calc(100vh - 80px);
  }
}

.Banner-start {
  @include sm {
    height: 320px;
  }
}

.Banner-img-gradient {
  position: absolute;
  width: 100%;
}

.Banner-img-gradient-top {
  transform: translateY(-20%);

  @include sm {
    transform: translateY(-10%);
  }
}

.Banner-img-gradient-left {
  transform: translateX(-20%);

  @include sm {
    transform: translateX(-10%);
  }
}

.Banner-img-gradient-right {
  transform: translateX(20%);

  @include sm {
    transform: translateX(10%);
  }
}

.Banner-img {
  position: absolute;
  max-width: 70%;

  @include sm {
    top: 70px;
    max-height: 100%;
  }
}

.Banner-end {
  flex-direction: column;
  align-items: start;

  @include sm {
    align-items: center;
  }

  @include md {
    padding-left: 40px;
  }

  @include lg {
    padding-left: 0;
  }
}

.Banner-end-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include sm {
    margin-top: 90px;
    margin-bottom: 30px;
    align-items: center;
  }
}

.Banner-link {
  display: flex;
  color: $yellow_d;
  font-weight: 400;
  font-size: 18px;
  align-items: center;
  text-decoration: none;

  svg {
    fill: $yellow_d;
  }
}

.Banner-title {
  font-style: normal;
  font-size: 28px;
  line-height: 38px;
  font-weight: 400;

  @include md {
    font-size: 48px;
    line-height: 65px;
  }

  @include xxl {
    font-size: 64px;
    line-height: 87px;
  }
}

.Banner-title-slogan {
  font-style: normal;
  font-size: 13px;
  color: $light-steal;

  @include lg {
    font-size: 18px;
    line-height: 25px;
  }

  @include xl {
    font-size: 22px;
  }
}

.Banner-info {
  color: $grey;
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 36px;

  @include md {
    font-size: 18px;
    line-height: 25px;
    padding-bottom: 100px;
  }

  @include xxl {
    font-size: 32px;
    line-height: 44px;
    padding-bottom: 80px;
  }
}

