@import "../../scss/utils";

.Wallet {
  padding-bottom: 60px;

  @include md {
    padding-bottom: 100px;
  }
}

.Section-space-top {
  padding-top: 60px;

  @include md {
    padding-top: 80px;
  }

  @include lg {
    padding-top: 110px;
  }

  @include xxl {
    padding-top: 160px;
  }
}

.Section-space-bottom {
  padding-bottom: 60px;

  @include md {
    padding-bottom: 80px;
  }

  @include lg {
    padding-bottom: 110px;
  }
}

.Section-head {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  padding-top: 60px;

  @include md {
    padding-top: 80px;
  }

  @include lg {
    padding-top: 110px;
  }
}

.Section-text,
.Section-info {
  display: flex;
  box-sizing: border-box;
  position: relative;
  align-items: center;
  align-self: center;
  justify-content: center;
  text-align: center;

  @include sm {
    width: 100%;
  }
}

.Section-text {
  font-size: 28px;
  line-height: 39px;
  font-weight: 400;
  color: #FFFFFF;
  padding-bottom: 20px;

  @include md {
    font-size: 48px;
    line-height: 66px;
    padding-bottom: 36px;
  }

  @include xxl {
    font-size: 64px;
    line-height: 87.17px;
    padding-bottom: 48px;
  }
}

.Section-70 {
  width: 70%;
}

.Section-info {
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  color: $grey;
  padding-bottom: 20px;

  @include md {
    padding-bottom: 48px;
  }

  @include xxl {
    font-size: 24px;
    line-height: 38px;
    padding-bottom: 60px;
  }
}

.Section-image-big {
  @include sm {
    height: 545px;
  }
}

.Wallet-screen {
  width: 100%;

  @include sm {
    height: 100%;
    object-fit: cover;
  }
}

.Wallet-purpose {
  padding-top: 60px;

  @include md {
    padding-top: 80px;
  }
}

.Wallet-download, .Featured-in {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 60px 0;

  @include sm {
    padding: 80px 0;
  }
}

.Section-download {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  @include sm {
    flex-direction: column;
  }
}

.Section-Download-text {
  align-items: center;
}
