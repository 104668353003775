@import "~normalize.css";
@import "./scss/utils";

body {
  font-family: $font-family-base;
  font-size: 62.6%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background: $black !important;
  color: $white !important;
}

// Typographic
.color-yellow {
  color: $yellow-d !important;
}

.text-grey {
  color: $grey !important;
}

.text-grey-50 {
  color: $grey_50 !important;
}

.text-steel-light {
  color: $steel_light !important;
}

.text-light-soft {
  color: $light-soft !important;
}

.text-gradient {
  background: -webkit-linear-gradient(0deg, #E1E1E5 0%, #8C8CB6 60%, #B5B5E6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-rounded {
  border-radius: 16px !important;
}

.color-link {
  color: $yellow-d;

  &:hover {
    color: darken($yellow-d, 10%);
  }
}

// Background
.bg-steel-10 {
  background-color: $steel_10 !important;
}

.bg-steel-20 {
  background-color: $steel_20 !important;
}

// height
.md-h-100 {
  @include md {
    height: 100% !important;
  }
}

.sm-hidden {
  @include sm {
    display: none;
  }
}

.md-hidden {
  @include md {
    display: none;
  }
}
