@import "../../scss/utils";

.Features {
  display: flex;
  flex-direction: column;
  margin: 0 -12px;

  a {
    color: $yellow-d;
  }

  @include lg {
    flex-direction: row;
  }
}

.Feature {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  margin: 0 12px;

  @include sm {
    margin: 0 0 40px;
  }

  @include md {
    margin-bottom: 12px;
  }
}

.Feature-card {
  padding: 20px 16px;
  background: $steel_10;
  border-radius: 16px;
  cursor: pointer;
}

.Feature-active {
  background: $steel_20;
}

.Feature-rtl {
  align-items: flex-end;

  @include sm {
    align-items: flex-start;
  }
}

.Feature-icon svg {
  width: 40px;
  height: 40px;

  @include lg {
    width: 28px;
    height: 28px;
  }

  @include xxl {
    width: 36px;
    height: 36px;
  }
}

.Feature-title {
  color: white;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  padding: 20px 0;
  margin-top: 20px;
  border-top: 1px solid rgba(128, 128, 133, 0.5);

  @include md {
    font-size: 16px;
    line-height: 19px;
  }

  @include xxl {
    font-size: 22px;
    line-height: 26px;
    margin-top: 24px;
    padding: 24px 0;
  }
}

.Feature-info {
  font-size: 16px;
  line-height: 22px;
  color: $grey;

  @include md {
    font-size: 12px;
    line-height: 17px;
  }

  @include xxl {
    font-size: 16px;
    line-height: 22px;
  }
}
