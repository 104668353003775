@import "../../scss/utils";

.Roadmap {
  overflow: hidden;
  position: relative;
}

.Roadmap-vertical {
  max-height: 100vh;
}

.Roadmap-slide .slick-slide {
  margin: 0 !important;
}

.Roadmap-step {
  position: relative;
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Roadmap-circle {
  text-align: center;
  position: absolute;
  top: -5px;
  transform: translateX(50%);
  z-index: 1;
}

.Roadmap-circle-active svg {
  fill: #FFA800;
}

.Roadmap-progress {
  display: flex;
  width: 100%;
  height: 8px;
  margin-top: 25px;
  background-color: $steel_20;
}

.Roadmap-progress-end {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.Roadmap-step-info {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  @include sm {
    .card {
      width: 80% !important;
    }
  }
}

.Roadmap-content-container {
  width: 50%;

  @include sm {
    width: 100% !important;
  }
}

.Roadmap-highlights {
  min-height: 300px;
  max-height: 500px;
  overflow-y: auto;
}
