@import "../../scss/utils";

.Page {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.Page-dark {
  background: $darkest;
}

.Page-black {
  background: #000000;
}

.Page-rtl {

}

.Page-content {
  display: flex;

  @include sm {
    flex-direction: column;
  }
}

.Page-content--rtl {
  flex-direction: row-reverse;

  @include sm {
    flex-direction: column;
  }
}

.Page-start, .Page-end {
  max-width: 50%;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include sm {
    max-width: 100%;
  }
}

.Page-end {
  @include md {
    padding: 40px 0;
  }

  @include xxl {
    padding: 54px 0;
  }
}

.Page-start {
  align-items: center;

  @include sm {
    padding-top: 60px;
  }
}

.Page-start--rtl {
  align-items: center;
}

.Page-img {
  //position: absolute;
  width: 70%;
  object-fit: contain;
  z-index: 1;

  @include sm {
    //width: 100%;
  }
}

.Page-title {
  padding-bottom: 20px;

  @include sm {
    margin-top: 55px;
  }

  @include md {
    padding-bottom: 36px;
  }

  @include xxl {
    padding-bottom: 48px;
  }
}

.Page-header-text {
  font-size: 28px;
  font-weight: 400;
  line-height: 38px;

  @include md {
    font-size: 48px;
    line-height: 65px;
  }

  @include xxl {
    font-size: 64px;
    line-height: 87px;
  }
}

.Page-info {
  padding-bottom: 36px;
  color: $grey;

  @include md {
    padding-bottom: 104px;
  }

  @include xxl {
    padding-bottom: 113px;
  }
}

.Page-info-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  @include xxl {
    font-size: 24px;
    line-height: 32px;
  }
}
