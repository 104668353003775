@import "../../scss/utils";

.Header {
  position: absolute;
  height: 80px;
  width: 100%;
  z-index: 2;
  transition: all .5s ease-in;
}

.Header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  animation: moveDown 0.2s ease-in-out;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

.Header-logo {
  height: 54px;
  position: absolute;
  top: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.nav {
  display: flex;
  flex-direction: row;
  font-size: 14px;

  a {
    text-decoration: none;
  }

  .nav-item {
    // height: 32px;
    padding: 0 20px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $grey;

    &:hover {
      color: $yellow-d;
    }
  }

  .nav-item-yellow {
    color: $yellow-d;
    font-weight: 500;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .nav-btn-item {
    padding: 9px 12px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $grey;
  }

  .nav-btn-wc svg {
    margin: 0;
  }

  @include sm {
    display: none;
  }
}

.hide-on-mobile {
  @include sm {
    display: none !important;
  }
}

.Menu-wrap {
  z-index: 2;
  display: none;
  cursor: pointer;

  @include sm {
    display: block;
  }
}

.Menu-close {
  display: none;
}

.Header-collapse {
  display: block;

  @include md {
    display: none;
  }
}

.Header-collapse-menu {
  display: none;
  overflow: hidden;
  position: absolute;
  background: $dark;
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  top: 0;
  z-index: 1;

  .nav {
    display: block;
    text-align: center;
  }

  .nav-item {
    padding: 20px 0;
    display: block;
  }

  .nav-btn-item {
    margin: 20px 0 !important;
  }
}

.nav-logo {
  display: flex;
  justify-content: center;
  padding-top: 30%;

  path {
    fill: $dark-steal;
  }
}
